export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  api: 'https://dev.api.iformacion.net',
  firebase: {
    apiKey: "AIzaSyDnTFydh7BZ7sscWS6rlETyKM5qdePy6nc",
    authDomain: "iformacion-dev.firebaseapp.com",
    projectId: "iformacion-dev",
    storageBucket: "iformacion-dev.appspot.com",
    messagingSenderId: "872077117913",
    appId: "1:872077117913:web:e8bfa11cca13ec8aa931c8"
  },
  useEmulators: false
};
